<template>
  <div class="sales-product-setting">
    <PageTitle title="銷售產品設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <BaseElInput v-model="search.name" clearable placeholder="請輸入產品名稱" @keypress.enter.native="refresh(); tableOptions.page = 1;" @clear="refresh(); tableOptions.page = 1;">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(); tableOptions.page = 1;" />
      </BaseElInput>
      <BaseElSelect
        v-model="search.property"
        clearable
        placeholder="請輸入產品性質"
        @change="refresh(); tableOptions.page = 1;"
      >
        <BaseElSelectOption
          v-for="item in salesProductPropertyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="salesProductList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="property" label="性質" align="center">
          <template slot-scope="scope">
            {{ scope.row.property ? salesProductPropertyOptions[scope.row.property].label : '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="defaultPrice" label="預設售價" align="center">
          <template slot-scope="scope">
            NT${{ scope.row.defaultPrice }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="SalesGroup" label="群組" align="center">
          <template slot-scope="scope">
            {{ (scope.row.SalesGroup && scope.row.SalesGroup.name) || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), selectRow=convertSelectedRow(scope.row), findSalesProduct()"
              @delete="deleteDialog=true, selectRow=convertSelectedRow(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesProductCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <section>
        <BaseElForm ref="form" :model="formData" label-position="top" :rules="formRules">
          <BaseElFormItem label="性質" prop="category">
            <BaseElRadioGroup v-model="formData.category">
              <BaseElRadio
                v-for="(item, key) in salesProductOptions"
                :key="key"
                :label="key"
              >
                {{ item.label }}
              </BaseElRadio>
            </BaseElRadioGroup>
          </BaseElFormItem>

          <BaseElFormItem v-if="formData.category === 'product' || formData.category === 'deposit'" label="名稱" prop="name">
            <BaseElInput v-model="formData.name" placeholder="請輸入產品名稱" />
          </BaseElFormItem>
          <BaseElFormItem v-if="formData.category === 'ticket'" label="堂票綁定" prop="ClassTicket">
            <ClassTicketSearch :model.sync="formData.ClassTicket" />
          </BaseElFormItem>

          <BaseElFormItem label="是否需要銷售人員" prop="needUnit">
            <BaseElSwitch
              v-model="formData.needUnit"
              active-text="是"
              inactive-text="否"
            />
          </BaseElFormItem>

          <BaseElFormItem label="預設售價" prop="defaultPrice">
            <BaseElInput v-model="formData.defaultPrice" placeholder="請輸入售價" />
          </BaseElFormItem>

          <BaseElFormItem label="群組" prop="SalesGroupId">
            <BaseElSelect
              v-model="formData.SalesGroupId"
              clearable
              placeholder="請選擇群組"
            >
              <BaseElSelectOption
                v-for="item in salesGroupOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </BaseElSelect>
          </BaseElFormItem>

          <BaseElFormItem v-if="formData.category === 'deposit'" label="儲值金額" prop="coinAmount">
            <BaseElInput v-model="formData.coinAmount" placeholder="請輸入儲值金額" />
          </BaseElFormItem>

          <BaseElFormItem label="排序" prop="order">
            <BaseElInput v-model="formData.order" placeholder="請輸入順序" />
          </BaseElFormItem>

          <BaseElFormItem label="抽潤設定" prop="revenueType" :error="priceSettingError">
            <BaseElRadioGroup v-model="formData.revenueType" @change="resetProfit">
              <div class="flex flex-col gap-[15px]">
                <BaseElRadio :label="null">
                  無 <BaseElInput style="cursor: pointer; opacity: 0; width: 0 !important; pointer-events: none;" />
                </BaseElRadio>
                <BaseElRadio label="value">
                  固定(元)
                  <BaseElInput
                    v-model="formData.revenueValue"
                    class="inline-input"
                    :class="{'is-error' : valueError}"
                    :disabled="formData.revenueType !== 'value'"
                    min="1"
                    type="number"
                  />
                </BaseElRadio>

                <BaseElRadio label="split">
                  先扣(元)
                  <BaseElInput
                    v-model="formData.revenueSplitCost"
                    class="inline-input"
                    :class="{'is-error' : costError}"
                    :disabled="formData.revenueType !== 'split'"
                    min="0"
                    type="number"
                  />
                  再乘
                  <BaseElInput
                    v-model="formData.revenueSplitRate"
                    class="inline-input"
                    :class="{'is-error' : rateError}"
                    :disabled="formData.revenueType !== 'split'"
                    min="1"
                    max="100"
                    type="number"
                  />
                  <i class="icon__percentage">%</i>
                </BaseElRadio>
              </div>
            </BaseElRadioGroup>
            <div v-show="formData.revenueSplitRate > 100" class="rate-error-hint">數值介於 0 至 100 之間</div>
          </BaseElFormItem>

          <BaseElFormItem label="銷售類別綁定" prop="category">
            <SalesCategorySelect :model.sync="formData.SalesCategories" :showAll="true" multiple />
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton plain @click="showDialog = false, resetForm()">
          取消
        </BaseElButton>
        <BaseElButton type="primary" @click="dialogConfirm">{{ (dialogType === 'create')? '新增' : '儲存' }}</BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteSalesProduct(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClassTicketSearch from '@/components/Search/ClassTicketSearch.vue'
import SalesCategorySelect from '@/components/Select/SalesCategorySelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import {
  CreateSalesProduct,
  GetSalesProduct,
  GetSalesProductCount,
  FindSalesProduct,
  UpdateSalesProduct,
  DeleteSalesProduct,
  GetSalesGroup,
  GetSalesGroupCount,
} from '@/api/sales'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, getAllDataFromApi } from '@/utils/helper'
import { salesProductPropertyOptions } from '@/config/sales'
import { checkUserFeature } from '@/store/modules/permission'
import { cloneDeep } from 'lodash'

export default {
  name: 'SalesProductSetting',
  components: { DeleteDialog, SalesCategorySelect, ClassTicketSearch, EmptyBlock },
  computed: {
    ...mapGetters([
      'shop',
      'userPlanFeature', 'userFeatures',
    ]),

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增銷售產品',
          update: '編輯銷售產品',
        },
      )
    },

    valueError () {
      const type = this.formData.revenueType
      const cost = this.formData.revenueValue
      if (type === 'split' || !type) return false

      return cost === '' || cost < 1
    },

    rateError () {
      const type = this.formData.revenueType
      const cost = this.formData.revenueSplitCost
      const rate = this.formData.revenueSplitRate
      if (type === 'value' || !type) return false

      return rate > 100 || rate === '' || cost === ''
    },

    costError () {
      const type = this.formData.revenueType
      const cost = this.formData.revenueSplitCost
      if (type === 'value' || !type) return false

      return cost === ''
    },

    salesGroupOptions () {
      return this.salesGroupList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },

    useWallet () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.salesProduct.create.useCoin')
    },

    salesProductOptions () {
      const options = cloneDeep(this.salesProductPropertyOptions)
      if (!this.useWallet) {
        delete options.deposit
        return options
      }
      return this.salesProductPropertyOptions
    },
  },
  data: () => ({
    salesProductPropertyOptions,
    loading: false,
    search: {
      name: '',
      property: '',
    },
    // Dialog
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,
    selectRow: null,
    selectSalesProduct: null,

    salesProductCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    priceSettingError: null,

    formData: {
      name: '',
      needUnit: true,
      defaultPrice: '',
      order: 100,
      SalesCategories: [],
      category: 'product',
      ClassTicket: '',
      revenueType: 'value', // split
      revenueValue: '',
      revenueSplitCost: '',
      revenueSplitRate: '',
      useCoin: false,
      coinAmount: '',
      SalesGroupId: '',
    },

    formRules: {
      name: noEmptyRules('請輸入名稱'),
      order: [isDigitRules(), noEmptyRules('請輸入排序'), rangeRules()],
      defaultPrice: [isDigitRules(), noEmptyRules('請輸入預設售價'), rangeRules()],
      coinAmount: [isDigitRules(), noEmptyRules('請輸入儲值金額'), rangeRules()],
      ClassTicket: [noEmptyRules('請選擇堂票')],
    },

    salesProductList: [],
    salesGroupList: [],
  }),

  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getSalesCategoryGroup()
      await this.getSalesProduct()
      await this.getSalesProductCount()
      this.loading = false
    },

    resetProfit () {
      this.priceSettingError = null
      this.formData.revenueValue = ''
      this.formData.revenueSplitCost = ''
      this.formData.revenueSplitRate = ''
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async dialogConfirm () {
      this.priceSettingError = null
      let formPass = await this.checkForm()
      if (this.rateError || this.costError || this.valueError) {
        formPass = false
        this.priceSettingError = '請輸入金額'
      }
      if (!formPass) return
      const type = this.dialogType
      if (type === 'create') this.createSalseProduct()
      if (type === 'update') this.updateSalesProduct()
      this.showDialog = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        needUnit: true,
        category: 'product',
        defaultPrice: '',
        order: 100,
        SalesCategories: [],
        revenueType: 'value',
        revenueValue: '',
        revenueSplitCost: '',
        revenueSplitRate: '',
        useCoin: false,
        coinAmount: '',
        SalesGroupId: '',
      }
    },

    //= > 創建銷售產品
    async createSalseProduct () {
      const form = this.formData
      try {
        await CreateSalesProduct({
          shopId: this.shop,
          name: (form.category === 'ticket') ? form.ClassTicket.name : form.name,
          needUnit: form.needUnit,
          defaultPrice: form.defaultPrice,
          order: form.order,
          salesCategories: extractList('id', form.SalesCategories),
          useClassTicket: form.category === 'ticket',
          classTicketId: (form.category === 'ticket') ? form.ClassTicket.id : undefined,
          revenueType: form.revenueType,
          revenueValue: (form.revenueType === 'value') ? form.revenueValue : undefined,
          revenueSplitCost: (form.revenueType === 'split') ? form.revenueSplitCost : undefined,
          revenueSplitRate: (form.revenueType === 'split') ? form.revenueSplitRate / 100 : undefined,
          useCoin: form.category === 'deposit',
          coinAmount: form.category === 'deposit' ? form.coinAmount : undefined,
          SalesGroupId: form.SalesGroupId || null,
        })
        await this.refresh()
        this.$message.success('新增成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售產品
    async getSalesProduct () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, err] = await GetSalesProduct({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: (this.search.name === '') ? undefined : this.search.name,
        useClassTicket: this.search.property === '' ? undefined : this.search.property === 'ticket',
        useCoin: this.search.property === '' ? undefined : this.search.property === 'deposit',
      })
      if (err) this.$message.error(err)
      if (res) this.salesProductList = res.map(item => ({ ...item, property: this.getProductProperty(item.useClassTicket, item.useCoin) }))
    },

    //= > 取得銷售產品總數
    async getSalesProductCount () {
      try {
        const res = await GetSalesProductCount({
          shopId: this.shop,
          name: (this.search.name === '') ? undefined : this.search.name,
          useClassTicket: this.search.property === '' ? undefined : this.search.property === 'ticket',
          useCoin: this.search.property === '' ? undefined : this.search.property === 'deposit',
        })

        this.salesProductCount = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得指定銷售產品
    async findSalesProduct () {
      try {
        const res = await FindSalesProduct({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        this.formData = JSON.parse(JSON.stringify(res))
        this.formData.revenueSplitRate *= 100
        this.formData = {
          ...this.formData,
          category: res.useClassTicket ? 'ticket' : res.useCoin ? 'deposit' : 'product',
        }
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 更新銷售產品
    async updateSalesProduct () {
      try {
        const form = this.formData
        await UpdateSalesProduct({
          shopId: this.shop,
          id: this.selectRow.id,
          name: (form.category === 'ticket') ? form.ClassTicket.name : form.name,
          needUnit: form.needUnit,
          defaultPrice: form.defaultPrice,
          order: form.order,
          salesCategories: extractList('id', form.SalesCategories),
          useClassTicket: form.category === 'ticket',
          classTicketId: (form.category === 'ticket') ? form.ClassTicket.id : undefined,
          revenueType: form.revenueType,
          revenueValue: (form.revenueType === 'value') ? form.revenueValue : null,
          revenueSplitCost: (form.revenueType === 'split') ? form.revenueSplitCost : null,
          revenueSplitRate: (form.revenueType === 'split') ? form.revenueSplitRate / 100 : null,
          useCoin: form.category === 'deposit',
          coinAmount: form.category === 'deposit' ? form.coinAmount : undefined,
          SalesGroupId: form.SalesGroupId || null,
        })

        await this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 刪除銷售產品
    async deleteSalesProduct () {
      try {
        await DeleteSalesProduct({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    getProductProperty (useClassTicket, useCoin) {
      return useClassTicket ? 'ticket' : useCoin ? 'deposit' : 'product'
    },

    convertSelectedRow (row) {
      return {
        ...row,
        category: this.getProductProperty(row.useClassTicket, row.useCoin),
      }
    },
    async getSalesGroupCount () {
      try {
        const res = await GetSalesGroupCount({
          shopId: this.shop,
        })
        return res.count
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async getSalesCategoryGroup () {
      const max = await this.getSalesGroupCount()
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        type: 'salesProduct',
      }
      const res = await getAllDataFromApi(max, GetSalesGroup, config)

      this.salesGroupList = res
    },
  },
}
</script>

<style scoped lang="scss">
.inline-input {
  width: 100px !important;
}

.rate-error-hint {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: red;
  font-weight: 400;
}

.icon__percentage {
  position: absolute;
  top: 50%;
  right: 8px;
  color: #767676;
  transform: translateY(-50%);
}

.search-container {
  display: flex;
  gap: 0 8px;
}

</style>
